import { useState, useEffect } from 'react';
import FactCarousel from '../components/Facts';
import './Create.css';

const facts = [
    "AI is transforming how we target ads with personalization.",
    "Programmatic advertising is driven by AI to optimize in real-time.",
    "By 2025, AI is projected to manage 80% of digital ads.",
    "AI analyzes user data faster than any human ever could.",
    "AI-driven marketing campaigns have increased ROI for brands by over 30%."
];

export default function App() {
    const [step, setStep] = useState(1);
    const [subject, setSubject] = useState('');
    const [url, setUrl] = useState('');
    const [objective, setObjective] = useState('');
    const [adCopy, setAdCopy] = useState('');
    const [promptOnly, setPromptOnly] = useState(false);
    const [loading, setLoading] = useState(false);
    const [prompt, setPrompt] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [fadeIn, setFadeIn] = useState(true);
    const [isReady, setIsReady] = useState(false);

    const handleNext = (event) => {
        event.preventDefault();
        if (step < 4) {
            setFadeIn(false); 
            setTimeout(() => {
                setStep(step + 1); 
            }, 300); 
        }
    };

    const handleBack = (event) => {
        event.preventDefault();
        if (step > 1) {
            setFadeIn(false); 
            setTimeout(() => {
                setStep(step - 1);
            }, 300);
        }
    };

    useEffect(() => {
        setFadeIn(false); 
        const timer = setTimeout(() => {
            setFadeIn(true); 
        }, 300); 
        return () => clearTimeout(timer);
    }, [step]);

    const handleSubmit = async (e) => {
        e.preventDefault(); 
        setPrompt('');
        setImageUrl('');
        setIsReady(false);  
        setLoading(true);     

        const userInput = { subject, url, objective, ad_copy: adCopy };
    
        try {
            const promptResponse = await fetch('https://images.forads.ai/api/generate-concept', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ prompt: generatePrompt(userInput) }),
            });
            const promptData = await promptResponse.json();
            setPrompt(promptData.choices[0].message.content);

            if (!promptOnly) {
                const imageResponse = await fetch('https://images.forads.ai/api/generate-image', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ prompt: promptData.choices[0].message.content }),
                });
                const imageData = await imageResponse.json();
                
                // Crear un objeto de imagen para cargar la imagen y esperar hasta que esté lista
                const img = new Image();
                img.src = imageData.data[0].url;
                img.onload = () => {
                    setImageUrl(imageData.data[0].url);
                    setIsReady(true);
                    // setLoading(false);
                };
            } else {
                setIsReady(true);
                // setLoading(false); 
            }
    
        } catch (error) {
            console.error('Error generando el prompt o la imagen:', error);
            // setLoading(false); // En caso de error, también detener el estado de carga
        } finally {
            setIsReady(true);
            const generatingElement = document.getElementById('generating');
            if (generatingElement) {
                generatingElement.classList.add('sliderRight');
                setLoading(false); 
            }        

        }
    
        const resultsElement = document.getElementById('results');
        const resultsBtn = document.getElementById('results_btn');
        document.body.style.setProperty('overflow-y', 'scroll', 'important');
        resultsElement.classList.remove('hidden');
        resultsElement.classList.add('sliderUp');
        resultsBtn.classList.remove('hidden');
        resultsBtn.classList.add('sliderUp');
    };

    const generatePrompt = (userInput) => {
        return `I am creating an online ad campaign and I need images for the ads. The subject of my ads is: ${userInput.subject}
                The URL for my subject is: ${userInput.url}
                The objective for my campaign is: ${userInput.objective}
                The copy for my ads is: ${userInput.ad_copy}
                Please create a detailed description of the image we should use for this ad. The image should be as simple as possible,
                containing just a few elements.`;
    };

    return (
        <div className="flex flex-col lg:flex-row bg-gray-200 h-screen">
            {/* Columna izquierda: Formulario */}
            <div className="w-full lg:w-1/3 xl:w-1/3 2xl:w-1/4 px-8 py-6 flex flex-col">
                <header className="flex flex-col items-start mb-6 space-y-4 mx-auto md:mx-0">
                    <a href="/">
                        <img src="./logo512.png" alt="Logo" className="invert m-[-7px] max-w-[210px] z-20 relative" />
                    </a>
                </header>

                {window.innerWidth < 1024 ? (
                    <div className="flex items-center justify-center min-h-screen mt-[-90px]">
                        {loading && (
                            <div
                                id="generating"
                                className={`w-screen h-screen absolute z-10 bg-gradient-to-r from-amber-500 to-pink-500 text-md text-white text-center left-0 top-0 flex items-center justify-center transition-opacity duration-500 ease-in-out ${loading && 'animate-fade-in'}`}
                            >
                                <div className="animate-scale-fade-in">
                                    <img src="./spinner.svg" alt="Loading spinner" className='mx-auto mb-3' />
                                    <FactCarousel facts={facts} duration={5000} />
                                </div>
                            </div>
                        )}

                        <div className="step-form">
                            <form id="profileForm" onSubmit={handleSubmit} className="space-y-6">
                                {step === 1 && (
                                    <div className={`transition-opacity duration-500 ease-in-out ${fadeIn ? 'opacity-100' : 'opacity-0'}`}>
                                        <h2 className="text-3xl mb-3 font-semibold">Tell us what you're advertising</h2>
                                        <input
                                            type="text"
                                            placeholder="product/service/brand"
                                            value={subject}
                                            onChange={(e) => setSubject(e.target.value)}
                                            required
                                            className="w-full p-3 border border-gray-300 rounded-md"
                                        />
                                        <button
                                            onClick={handleNext}
                                            disabled={!subject.trim()}
                                            className={`w-full text-white font-bold py-4 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 mt-4 ${!subject.trim() ? 'bg-gray-400 cursor-not-allowed' : 'bg-gradient-to-r from-cyan-500 to-blue-500'}`}
                                        >
                                            Next
                                        </button>
                                    </div>
                                )}

                                {step === 2 && (
                                    <div className={`transition-opacity duration-500 ease-in-out ${fadeIn ? 'opacity-100' : 'opacity-0'}`}>
                                        <h2 className="text-3xl mb-3 font-semibold">Tell us about your ad campaign</h2>
                                        <input
                                            type="text"
                                            placeholder="objective"
                                            value={objective}
                                            onChange={(e) => setObjective(e.target.value)}
                                            required
                                            className="w-full p-3 border border-gray-300 rounded-md"
                                        />
                                        <input
                                            type="text"
                                            placeholder="Ad Copy"
                                            value={adCopy}
                                            onChange={(e) => setAdCopy(e.target.value)}
                                            required
                                            className="w-full p-3 border border-gray-300 rounded-md mt-4"
                                        />
                                        <button
                                            onClick={handleNext}
                                            disabled={!objective.trim() || !adCopy.trim()}
                                            className={`w-full text-white font-bold py-4 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 mt-4 ${!objective.trim() || !adCopy.trim() ? 'bg-gray-400 cursor-not-allowed' : 'bg-gradient-to-r from-cyan-500 to-blue-500'}`}
                                        >
                                            Next
                                        </button>
                                        <a
                                            href="#!"
                                            onClick={handleBack}
                                            className="w-full block text-pink-500 font-bold focus:outline-none underline mx-auto mt-4 text-center"
                                        >
                                            Go Back
                                        </a>
                                    </div>
                                )}

                                {step === 3 && (
                                    <div className={`transition-opacity duration-500 ease-in-out ${fadeIn ? 'opacity-100' : 'opacity-0'}`}>
                                        <h2 className="text-3xl mb-3 font-semibold">Where will your ad point to?</h2>
                                        <input
                                            type="text"
                                            placeholder="URL"
                                            value={url}
                                            onChange={(e) => setUrl(e.target.value)}
                                            required
                                            className="w-full p-3 border border-gray-300 rounded-md"
                                        />
                                        <button
                                            type="button"
                                            onClick={handleNext}
                                            disabled={!url.trim()}
                                            className={`w-full text-white font-bold py-4 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 mt-4 ${!url.trim() ? 'bg-gray-400 cursor-not-allowed' : 'bg-gradient-to-r from-cyan-500 to-blue-500'}`}
                                        >
                                            Next
                                        </button>
                                        <a
                                            href="#!"
                                            onClick={handleBack}
                                            className="w-full block text-pink-500 font-bold focus:outline-none underline mx-auto mt-4 text-center"
                                        >
                                            Go Back
                                        </a>
                                    </div>
                                )}

                                {step === 4 && (
                                    <div className={`transition-opacity duration-500 ease-in-out ${fadeIn ? 'opacity-100' : 'opacity-0'}`}>

                                        <div className="prompt-only flex items-center space-x-2 mb-4">
                                            <div className="flex items-center space-x-3">
                                                <label htmlFor="prompt-only" className="flex items-center cursor-pointer">
                                                    <div className="relative scale-75">
                                                        <input
                                                            id="prompt-only"
                                                            type="checkbox"
                                                            checked={promptOnly}
                                                            onChange={() => setPromptOnly(!promptOnly)}
                                                            className="sr-only"
                                                        />
                                                        <div
                                                            className={`block w-14 h-8 rounded-full ${promptOnly ? 'bg-blue-500' : 'bg-gray-300'}`}
                                                        ></div>
                                                        <div
                                                            className={`absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition-transform ${promptOnly ? 'translate-x-6' : ''}`}
                                                        ></div>
                                                    </div>
                                                    <span className="text-gray-700 text-sm">
                                                        Just give me the prompt (no image)
                                                    </span>
                                                </label>
                                            </div>
                                        </div>

                                        <button
                                            type="submit"
                                            disabled={loading}
                                            className={`w-full py-4 text-2xl rounded-md hover:bg-gradient-to-r hover:from-fuchsia-700 hover:to-pink-700 ${loading ? 'cursor-not-allowed text-white bg-gradient-to-r from-stone-500 to-stone-700' : 'bg-gradient-to-r from-fuchsia-600 to-pink-600 text-white'}`}
                                        >
                                            Ignite the AI Genius
                                        </button>

                                        <a
                                            href="#!"
                                            onClick={handleBack}
                                            className="w-full block text-pink-500 font-bold focus:outline-none underline mx-auto mt-4 text-center"
                                        >
                                            Go Back
                                        </a>
                                    </div>
                                )}

                            </form>
                        </div>
                    </div>
                ) : (


                    <form id="profileForm" onSubmit={handleSubmit} className="space-y-6">
                        <div className="section ad space-y-4">
                            <h2 className="text-lg font-semibold -mb-2">Tell us what you're advertising</h2>
                            <div className="accordion space-y-2">
                                <input
                                    type="text"
                                    placeholder="product/service/brand"
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                    required
                                    className="w-full p-3 border border-gray-300 rounded-md"
                                />
                                <p className="text-gray-500 text-sm">Enter the name and description of the product, service, or brand you are advertising.</p>
                                <input
                                    type="text"
                                    placeholder="URL"
                                    value={url}
                                    onChange={(e) => setUrl(e.target.value)}
                                    required
                                    className="w-full p-3 border border-gray-300 rounded-md"
                                />
                                <p className="text-gray-500 text-sm">Provide the URL where users can learn more about your product, service, or brand.</p>
                            </div>
                        </div>

                        <div className="section campaign space-y-4">
                            <h2 className="text-lg font-semibold -mb-2">Tell us about your ad campaign</h2>
                            <div className="accordion space-y-2">
                                <input
                                    type="text"
                                    placeholder="objective"
                                    value={objective}
                                    onChange={(e) => setObjective(e.target.value)}
                                    required
                                    className="w-full p-3 border border-gray-300 rounded-md"
                                />
                                <p className="text-gray-500 text-sm">What is the main goal of your ad campaign?</p>
                                <input
                                    type="text"
                                    placeholder="Ad Copy"
                                    value={adCopy}
                                    onChange={(e) => setAdCopy(e.target.value)}
                                    required
                                    className="w-full p-3 border border-gray-300 rounded-md"
                                />
                                <p className="text-gray-500 text-sm">Enter the text for your ad here.</p>
                            </div>
                        </div>

                        <div className="section submit space-y-4  2xl:pt-15">

                            <div className="prompt-only flex items-center -mb-2">
                                <div className="flex items-center space-x-3">
                                    <label htmlFor="prompt-only" className="flex items-center cursor-pointer">
                                        <div className="relative scale-75">
                                            <input
                                                id="prompt-only"
                                                type="checkbox"
                                                checked={promptOnly}
                                                onChange={() => setPromptOnly(!promptOnly)}
                                                className="sr-only"
                                            />
                                            <div className={`block w-14 h-8 rounded-full ${promptOnly ? 'bg-blue-500' : 'bg-gray-300'}`}></div>
                                            <div className={`absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition-transform ${promptOnly ? 'translate-x-6' : ''}`}></div>
                                        </div>
                                        <span className="text-gray-700 text-sm">
                                            Just give me the prompt (no image)
                                        </span>
                                    </label>
                                </div>
                            </div>

                            <button
                                type="submit"
                                disabled={loading}
                                className={`w-full py-4 text-2xl rounded-md ${loading ? 'cursor-not-allowed text-white bg-gradient-to-r from-stone-500 to-stone-700' : 'bg-gradient-to-r from-fuchsia-600 to-pink-600 text-white hover:bg-gradient-to-r hover:from-fuchsia-700 hover:to-pink-700'}`}
                            >
                                Ignite the AI Genius
                            </button>


                        </div>
                    </form>
                )}
            </div>

            {/* Columna derecha: Contenido generado */}
            <div id="results" className="lg:!flex lg:!w-2/3 xl:!w-3/4 px-8 py-6 hidden flex-col space-y-10 lg:space-y-0 lg:!relative lg:!pt-5 lg:!animate-none">
            {/* Mostrar un div si al menos una de las condiciones es verdadera (prompt o imageUrl son falsos) */}
                {(!prompt || (!promptOnly && !imageUrl)) && (
                    <div>
                    {loading && (
                        <div
                        id="generating"
                        className={`w-full h-full absolute z-10 bg-gradient-to-r from-amber-500 to-pink-500 text-md text-white text-center left-0 top-0 flex items-center justify-center transition-opacity duration-500 ease-in-out absolute ${loading && 'animate-fade-in'}`}
                        >
                        <div className="animate-scale-fade-in">
                        <img src="./spinner.svg" alt="Loading spinner" className='mx-auto mb-3' />
                        <FactCarousel facts={facts} duration={5000} />
                        </div>
                        </div>
                    )}

                    <div className="text-center bg-gray-300 rounded-lg h-screen max-h-[83%] text-gray-500 mt-10 flex items-center justify-center relative">
                        <p>No data available. Please submit to generate a prompt or image.</p>
                    </div>
                    </div>
                )}

                <div className='w-full lg:w-2/3 mx-auto'>
                    {/* Mostrar el prompt generado */}
                    {isReady && prompt && (
                        <div className="section prompt w-full lg:w-full">
                            <h2 className='text-lg font-semibold mt-1 mb-3 text-center'>Generated Prompt</h2>
                            <div className='bg-white rounded-lg p-5 shadow-xl'>
                                <p>{prompt}</p>
                            </div>
                        </div>
                    )}

                    {/* Mostrar la imagen generada si está disponible */}
                    {isReady && imageUrl && !promptOnly && (
                        <div className="section image w-full lg:w-full mt-10 lg:mt-10 pb-[80px]">
                            <h2 className='text-lg font-semibold mb-3 text-center'>Generated Image</h2>
                            <img src={imageUrl} alt="" className='rounded-md shadow-lg' />
                        </div>
                    )}

                </div>

            </div>

            <div id="results_btn" className='left-0 w-full h-40 createSliderBack hidden lg:hidden'>
                <button
                    onClick={() => window.location.reload()}
                    className="w-90 absolute bottom-2 left-2 right-2 bg-pink-600 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-opacity-50 md:block"
                >
                    + Generate a new image
                </button>
            </div>

        </div>
    );
}