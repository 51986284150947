import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Create from './pages/Create';
import './App.css';

const ApplyBodyId = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      document.body.id = 'home-page';
    } else if (location.pathname === '/create') {
      document.body.id = 'create-page';
    }

    return () => {
      document.body.id = '';
    };
  }, [location]);

  return null;
};

function App() {
  return (
    <Router>
      <ApplyBodyId />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/create" element={<Create />} />
      </Routes>
    </Router>
  );
}

export default App;
